.footerHolder{
    width: 100%;
    margin: 0px auto;
    background-color: #161616;
}

.footerMain{
    margin: 0px auto;
    max-width: 1300px;
    width: 100%;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
}

.footerMain h2{
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 500;
    font-family: "EB Garamond", serif;
}

.footerLeft{
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footerMiddle{
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}

.footerMiddle p{
    margin: 0;
    cursor: pointer;
}

.socialMedia{
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.smallIcons{
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}





