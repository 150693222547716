.aboutHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Playfair Display';
}

.aboutMain{
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.imageAndShortText{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 8rem 0;
}

.imageAndShortText h2{
    text-align:start;
    max-width: 750px;
    width: 100%;
    font-size: 40px;
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-family: "EB Garamond", serif;
    margin: 0;
}

.biggerImageAndLongerText{
    max-width: 1390px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7rem;
}

.justText{
    max-width: 750px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.justText h1 {
    font-size: 70px;
    font-weight: 400;
    text-decoration: underline;
    -webkit-text-decoration-color: #ffa62b;
    text-decoration-color: #ffa62b;
}

.justText p{
    font-family: "Poppins", Arial, sans-serif;
    font-size: 17px;
    line-height: 1.8;
    font-weight: 400;
    /* color: #4d4d4d; */
    color: black;
    margin-top: 0;
    margin-bottom: 1rem;
}


