.blogPostsHolder{
    margin: 50px auto;
    width: 100%;
}

.blogPostsMain{
    margin: 0px auto;
   /*  max-width: 1300px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogPostsGrid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items:center;
    align-content: center;
}

.blogPostsUpper{
    text-align: center;
    padding: 30px 0px;
}

.blogPostsUpper h4{
    color: #ffa62b;
    font-family: "Poppins", Arial, sans-serif;
    margin-bottom: 0;
}

.blogPostsUpper h1{
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-family: "EB Garamond", serif;
    font-size: 70px;
    margin: 0px auto;
}