.homeViewHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffa62b;
    font-family: 'Playfair Display';
}

.homeViewMain{
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.homeViewMain h2{
    font-size: 43px;
    margin-bottom: 0;
    font-weight: 500;
}

.nameAndImage{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.nameAndImage h1{
    text-align: center;
    font-weight: 700;         
    font-size: 8vw;
    line-height: 1;
    letter-spacing: 10px;
    margin-bottom: 0;
    margin-top: 0;
}


