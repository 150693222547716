.serviceOfferingHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Playfair Display';
}

.serviceOfferingMain{
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 0;
}

.upperText{
    text-align: center;
}

.upperText h4{
    color: #ffa62b;
    font-family: "Poppins", Arial, sans-serif;
    margin-bottom: 0;
}

.upperText h1{
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-family: "EB Garamond", serif;
    font-size: 70px;
    margin: 0px auto;
}

.upperText p{
    font-size: 17px;
    font-family: "Poppins", Arial, sans-serif;    
    line-height: 1.8;
    font-weight: 500;
    color: #4d4d4d;
    max-width: 800px;
    width: 100%;
    margin-bottom: 3rem;
}

.bottomImages{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
}





