.oneServiceHolder{
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.oneServiceHolder:hover > .overlay{
    display: flex;
    /* opacity: 0.8; */
}

.oneServiceHolder:hover > .textTransition{
    display: flex;
}

.oneServiceHolder img{
    max-width: 500px;
    width: 100%;
}

.textTransition{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    width: 90%;
    height: 100%;
    color: black;
    margin: 0px auto;
    font-size: 24px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-family: "EB Garamond", serif;
}

.textTransition h3{
    font-size: 34px
}

.overlay{
    display: none;
    /* opacity: 0; */
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: orange;
    animation: opacity-animation 0.2s forwards;
}

@keyframes opacity-animation {
    0%    { opacity: 0; }
  100%  { opacity: 0.8; }
}

